import { AppActions, AppActionsType, AppStateType } from '../constant/app.const'
import { AppType } from '../../type/AppType'
import { BannerType } from '../../type/BannerType'
import { FrontTheme } from '../../type/FrontTheme'

const initialState: AppStateType = {
    isLoading: false,
    settings: {} as AppType,
    carousel: [],
    banner: [],
    footerBanner: [],
    nftMallBanners: {} as BannerType,
    middleBanners: [],
    theme: {} as FrontTheme,
    isShowSorryPage: false,
}

function appReducers(state = initialState, actions: AppActions): AppStateType {
    switch (actions.type) {
        case AppActionsType.setSettings:
            return { ...state, settings: actions.payload }
        case AppActionsType.setLoading:
            return { ...state, isLoading: actions.payload }
        case AppActionsType.setCarousel:
            return { ...state, carousel: actions.payload }
        case AppActionsType.setBanner:
            return { ...state, banner: actions.payload }
        case AppActionsType.setFooterBanner:
            return { ...state, footerBanner: actions.payload }
        case AppActionsType.setNftMallBanner:
            return { ...state, nftMallBanners: actions.payload }
        case AppActionsType.setMiddleBanner:
            return { ...state, middleBanners: actions.payload }
        case AppActionsType.setTheme:
            return { ...state, theme: actions.payload }
        case AppActionsType.setIsShowSorryPage:
            return { ...state, isShowSorryPage: actions.payload }
        default:
            return state
    }
}

export default appReducers
