import axios, { AxiosInstance, AxiosResponse, AxiosError } from 'axios'
import { message } from 'antd'
import { AuthService } from './auth.service'
import store from '../store'
import { AppActionsType } from '../store/constant/app.const'

class CmsService {
    public static baseURL =
        process.env.REACT_APP_API_URL ?? 'http://localhost:1337'

    protected client: AxiosInstance

    constructor() {
        this.client = axios.create({
            baseURL: CmsService.baseURL,
            headers: {
                Accept: 'application/json',
            },
        })

        if (AuthService.isAuthenticated()) {
            this.client.defaults.headers.Authorization = `Bearer ${AuthService.getToken()}`
        }

        this.client.interceptors.response.use(
            CmsService.handleFullFilled,
            CmsService.handleRejected
        )
    }

    private static handleFullFilled(response: AxiosResponse): AxiosResponse {
        return response
    }

    private static handleRejected(error: AxiosError | Error) {
        if (axios.isAxiosError(error)) {
            if (error.response) {
                const { status } = error?.response

                switch (status) {
                    case 404:
                        // message.error('データの読み込みに失敗しました。 ')
                        break
                    case 401:
                        window.location.href = `${process.env.REACT_APP_EC_API_URL}/mypage/login`
                        localStorage.clear()
                        break
                    case 403:
                        message.error('データへのアクセスが拒否されました。')
                        break
                    case 504:
                        // store.dispatch({
                        //     type: AppActionsType.setIsShowSorryPage,
                        //     payload: true,
                        // })
                        break
                    default:
                        break
                }
            } else if (error.message === 'Network Error') {
                // store.dispatch({
                //     type: AppActionsType.setIsShowSorryPage,
                //     payload: true,
                // })
            }
        }

        return Promise.reject(error)
    }
}

export default CmsService
