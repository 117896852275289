import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { message } from 'antd'
import { handleRedirectMyPageNFT, redirectLogin } from '../utils/utils'
import { useAppSelector } from '../store/hooks/AppHooks'

function Protected(): JSX.Element {
    const history = useHistory()
    const location = useLocation()
    const isAuth = useAppSelector((state) => state.user.isAuth)
    const userProfile = useAppSelector((state) => state.user.me)

    useEffect(() => {
        message.warn(
            'このページにアクセスするためにログインする必要があります。'
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (
            location.pathname === '/portal/favorites' ||
            location.pathname === '/portal/setting-creator'
        ) {
            redirectLogin()
            return
        }
        handleRedirectMyPageNFT(isAuth, userProfile.pialaAuthToken)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname])

    return <></>
}

export default Protected
