import axios, { AxiosInstance, AxiosResponse } from 'axios'
import { AppActionsType } from '../store/constant/app.const'
import store from '../store'

class EcService {
    public static baseURL =
        process.env.REACT_APP_EC_API_URL ?? 'http://localhost:1337'

    public static ecApiKey = process.env.REACT_APP_EC_API_KEY ?? ''

    protected client: AxiosInstance

    constructor() {
        this.client = axios.create({
            baseURL: EcService.baseURL,
            headers: {
                Accept: 'application/json',
            },
        })

        this.client.defaults.headers.authorization = EcService.ecApiKey

        this.client.interceptors.response.use(
            EcService.handleFullFilled,
            EcService.handleRejected
        )
    }

    private static handleFullFilled(response: AxiosResponse): AxiosResponse {
        return response
    }

    private static handleRejected(error: any) {
        if (axios.isAxiosError(error)) {
            if (error.response) {
                const { status } = error?.response

                switch (status) {
                    case 504:
                        // store.dispatch({
                        //     type: AppActionsType.setIsShowSorryPage,
                        //     payload: true,
                        // })
                        break
                    default:
                        break
                }
            } else if (error.message === 'Network Error') {
                // store.dispatch({
                //     type: AppActionsType.setIsShowSorryPage,
                //     payload: true,
                // })
            }
        }

        return Promise.reject(error)
    }
}

export default EcService
