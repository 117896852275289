import React from 'react'
import { RouteChildrenProps } from 'react-router-dom'
import Header from '../components/portal/Header'
import HeaderMainMenu from '../components/portal/HeaderMainMenu'
import GachaRoutes from '../routes/GachaRoutes'
import withMasterLayout from '../components/hoc/WithMasterLayout'

export const GachaContext = React.createContext({})

function Layout({ match }: RouteChildrenProps): JSX.Element {
    return (
        <GachaContext.Provider value={{}}>
            <section className="gacha gacha__wrapper">
                <div className="gacha__container">
                    <Header />
                    <HeaderMainMenu />
                    <GachaRoutes prefix={match ? match.path : ''} />
                    {/* <BottomNav /> */}
                </div>
            </section>
        </GachaContext.Provider>
    )
}

const GachaLayout = withMasterLayout<RouteChildrenProps>(Layout)
export default GachaLayout
