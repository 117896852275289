import React from 'react'

function SorryPage(): JSX.Element {
    return (
        <div
            style={{
                display: 'flex',
                height: '100vh',
            }}
        >
            <div
                style={{
                    textAlign: 'center',
                    margin: 'auto',
                    fontSize: '20px',
                }}
            >
                <b>
                    アクセス集中により現在ページが閲覧しにくい状態となっております。
                    <br />
                    しばらく経ってから再度アクセスしてください。
                    <br />
                    CYBER STAR
                </b>
                <br />
                <br />
                <a
                    href="https://shop.cyberstar.jp/mypage/login"
                    style={{
                        color: 'rgb(85, 26, 139)',
                        textDecoration: 'underline',
                    }}
                >
                    ログインページへ
                </a>
            </div>
        </div>
    )
}
export default SorryPage
