import React from 'react'
import { Link } from 'react-router-dom'
import { Menu } from 'antd'
import { t } from 'i18next'
import { useAppSelector } from '../../store/hooks/AppHooks'
import EcService from '../../services/ec.service'
import NftService from '../../services/nft.service'

type HeaderMenuOverlayPropsType = { handleVisible: () => void }

const HeaderMenuOverlay = ({
    handleVisible,
}: HeaderMenuOverlayPropsType): JSX.Element => {
    const auth = useAppSelector((state) => state.user.isAuth)
    const currentLink = window.location.href
    const userProfile = useAppSelector((state) => state.user.me)
    const authId = useAppSelector((state) => state.user.me.pialaAuthToken)
    const profileLink = `${process.env.REACT_APP_EC_API_URL}/mypage${
        !auth ? `/login?redirect=${currentLink}` : ''
    }`

    const handleRedirectUrl = async (path = 'mypage') => {
        if (auth) {
            const url = await NftService.getRedirectUrl(
                userProfile.pialaAuthToken,
                path
            )
            window.location.href = url
        } else {
            window.location.href = profileLink
        }
    }

    const handleMallClick = () => {
        if (auth) {
            NftService.getRedirectUrl(authId, 'auctions').then((url) => {
                window.location.href = url
            })
        } else {
            NftService.getRedirectUrl(null, 'auctions').then((url) => {
                window.location.href = url
            })
        }
    }
    return (
        <Menu
            className="portal__header__side-menu-menu__overlay border-r-none"
            onClick={handleVisible}
        >
            {!auth && (
                <Menu.Item
                    className="portal__header__side-menu-menu__item"
                    key={Math.floor(300000 * Math.random())}
                >
                    <a href={profileLink}>ログイン</a>
                </Menu.Item>
            )}
            {auth && (
                <Menu.Item
                    className="portal__header__side-menu-menu__item"
                    key={Math.floor(300000 * Math.random())}
                >
                    <div
                        role="button"
                        tabIndex={0}
                        onKeyDown={() => handleRedirectUrl()}
                        onClick={() => handleRedirectUrl()}
                    >
                        マイページ
                    </div>
                </Menu.Item>
            )}
            <Menu.Item
                className="portal__header__side-menu-menu__item"
                key={Math.floor(300000 * Math.random())}
            >
                <Link to="/portal/favorites">お気に入り</Link>
            </Menu.Item>
            <Menu.Item
                className="portal__header__side-menu-menu__item"
                key={Math.floor(300000 * Math.random())}
            >
                <Link to="/portal/listFC">クラブ</Link>
            </Menu.Item>
            <Menu.Item
                className="portal__header__side-menu-menu__item"
                key={Math.floor(300000 * Math.random())}
            >
                <a
                    href={`${process.env.REACT_APP_EC_API_URL}${process.env.REACT_APP_POINT_PRODUCTS}`}
                >
                    ポイント購入
                </a>
            </Menu.Item>
            <Menu.Item
                className="portal__header__side-menu-menu__item"
                key={Math.floor(300000 * Math.random())}
            >
                <Link to="/gacha">カプセル</Link>
            </Menu.Item>
            <Menu.Item
                className="portal__header__side-menu-menu__item"
                key={Math.floor(300000 * Math.random())}
            >
                <a
                    href={`${process.env.REACT_APP_EC_API_URL}${process.env.REACT_APP_EC_PRODUCTS_ORDER_BY}`}
                >
                    カプセルコイン購入
                </a>
            </Menu.Item>
            <Menu.Item
                className="portal__header__side-menu-menu__item"
                key={Math.floor(300000 * Math.random())}
            >
                <div
                    role="button"
                    tabIndex={0}
                    onKeyDown={() => handleMallClick()}
                    onClick={() => handleMallClick()}
                >
                    オークション
                </div>
            </Menu.Item>
            <Menu.Item
                className="portal__header__side-menu-menu__item"
                key={Math.floor(300000 * Math.random())}
            >
                <Link to="/portal/guide/user">使い方ガイド</Link>
            </Menu.Item>
            <Menu.Item
                className="portal__header__side-menu-menu__item"
                key={Math.floor(300000 * Math.random())}
            >
                <a href="https://contents.cyberstar.jp/personal_portal">
                    CYBER STARとは
                </a>
            </Menu.Item>
            <Menu.Item
                className="portal__header__side-menu-menu__item"
                key={Math.floor(300000 * Math.random())}
            >
                <Link to="/portal/faq">FAQ</Link>
            </Menu.Item>
            <Menu.Item
                className="portal__header__side-menu-menu__item"
                key={Math.floor(300000 * Math.random())}
            >
                <Link to="/portal/contact">お問い合わせ</Link>
            </Menu.Item>
            <Menu.Item
                className="portal__header__side-menu-menu__item"
                key={Math.floor(300000 * Math.random())}
            >
                <a href={`${EcService.baseURL}/entry/artist`}>
                    クリエイター申請
                </a>
            </Menu.Item>
            {auth ? (
                <Menu.Item key={Math.floor(300000 * Math.random())}>
                    <a
                        type="button"
                        href={`${process.env.REACT_APP_EC_API_URL}/prelogout`}
                    >
                        ログアウト
                    </a>
                </Menu.Item>
            ) : null}
        </Menu>
    )
}
export default HeaderMenuOverlay
