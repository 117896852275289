import { Action } from 'redux'
import { AppType } from '../../type/AppType'
import { CarouselType } from '../../type/CarouselType'
import { BannerType } from '../../type/BannerType'
import { FrontTheme } from '../../type/FrontTheme'

export type AppStateType = {
    isLoading: boolean
    settings: AppType
    carousel: CarouselType[]
    banner: BannerType[]
    footerBanner: BannerType[]
    nftMallBanners: BannerType
    middleBanners: BannerType[]
    theme: FrontTheme
    isShowSorryPage: boolean
}

export interface AppActions<T = any> extends Action {
    payload?: T
}

export const AppActionsType = {
    fetchSettings: 'APP_FETCH_SETTINGS',
    fetchCarousel: 'APP_FETCH_CAROUSEL',
    setSettings: 'APP_SET_SETTINGS',
    setLoading: 'APP_SET_LOADING',
    setCarousel: 'APP_SET_CAROUSEL',
    fetchBanner: 'APP_FETCH_BANNER',
    fetchFooterBanner: 'APP_FETCH_FOOTER_BANNER',
    fetchNftMallBanner: 'APP_FETCH_NFT_MALL_BANNER',
    fetchMiddleBanner: 'APP_FETCH_MIDDLE_BANNER',
    setBanner: 'APP_SET_BANNER',
    setFooterBanner: 'APP_SET_FOOTER_BANNER',
    setNftMallBanner: 'APP_SET_NFT_MALL_BANNER',
    setMiddleBanner: 'APP_SET_MIDDLE_BANNER',
    fetchAll: 'FETCH_ALL',
    setTheme: 'APP_FETCH_THEME',
    setIsShowSorryPage: 'APP_SET_IS_SHOW_SORRY_PAGE',
}
