import React, { MouseEventHandler, useState } from 'react'
import { Link } from 'react-router-dom'
import { t } from 'i18next'
import HeaderNavigation from './HeaderNavigation'
import HeaderMenu from './HeaderMenu'
import ClickableWrapper from '../common/ClickableWrapper'
import NftService from '../../services/nft.service'
import { useAppSelector } from '../../store/hooks/AppHooks'
import useGetWidthSize from '../../hook/useGetWidthSize'

type Props = {
    navigationStyles?: React.CSSProperties
    isClickable?: boolean
    isShowHeader?: boolean
    isEditable?: boolean
}
const HeaderMainMenu = (props: Props): JSX.Element => {
    const {
        navigationStyles,
        isClickable = true,
        isShowHeader,
        isEditable,
    } = props
    const [isDisplayInput] = useState(false)
    const isAuth = useAppSelector((state) => state.user.isAuth)
    const userProfile = useAppSelector((state) => state.user.me)
    const { width } = useGetWidthSize()

    const handleRedirectUrl = async (path = 'mypage') => {
        if (isAuth) {
            const url = await NftService.getRedirectUrl(
                userProfile.pialaAuthToken,
                path
            )
            window.location.href = url
        } else {
            window.location.href = `${process.env.REACT_APP_EC_API_URL}/mypage/login`
        }
    }

    return (
        <>
            {!isEditable && !isShowHeader ? (
                <div className="portal__header__hamburger">
                    <ClickableWrapper isClickable={isClickable}>
                        <HeaderMenu
                            styleIconBar={{
                                color: 'rgb(0, 0, 0)',
                            }}
                        />
                    </ClickableWrapper>
                </div>
            ) : null}
        </>
    )
}

HeaderMainMenu.defaultProps = {
    navigationStyles: null,
    isClickable: true,
    isShowHeader: true,
    isEditable: true,
}

export default HeaderMainMenu
